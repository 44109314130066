import React from "react";
import Header from "../../../../components/header/Header";
import Content from "../../../../components/main/content/Content";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/Footer";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  let temp = data?.cms?.recent_listicle;
  let featured_post = temp.filter(checkFetured);
  function checkFetured(element) {
    return element.is_feature_post;
  }
  return (
    <div className="layout">
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WM27LRZQNE"></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WM27LRZQNE');
          `}
        </script>
        <meta name="webgains-site-verification" content="9za3nmsz" />
        <meta name="partnerboostverifycode" content="32dc01246faccb7f5b3cad5016dd5033" />
        <meta name="lhverifycode" content="32dc01246faccb7f5b3cad5016dd5033" />
      </Helmet>
      <Header category={data?.cms?.categories} domain={data?.cms?.domain} allPosts={allPosts} engine={options} pages={data?.cms?.pages} />
      <div className="container is-max-widescreen">
        <div className="columns m-0">
          <Content story={data?.cms?.page} domain={data?.cms?.domain} />
          <Sidebar recent_story={featured_post} />
        </div>
      </div>
      <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"editor@ofmum.com"} />
    </div>
  );
};
export default ArticlePage;

export const query = graphql`
  query pages($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "8") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      categories: CMS_CategoryByDomain(domain_id: "8") {
        name
        slug
      }
      recent_listicle: CMS_DomainWiseLists(domain_id: "8") {
        title
        is_feature_post
        image
        slug
        content: description
        pub_date
        categories {
          name
        }
      }
      page: CMS_Page(id: $slug) {
        content
        slug
        title
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        name: title
      }
    }
  }
`;
